<template>
  <section class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link to="/admin/cursos">Curso: {{breadcrumb.course}}</router-link>
        </li>
        <li>
          <router-link :to="{name: 'Módulos', params:{ name: $route.params.cur }}">{{breadcrumb.module}}</router-link>
        </li>
        <li>
          <router-link
            :to="{name: 'Lição', params:{ name: $route.params.mod, cur: $route.params.cur}}"
          >Lição: {{breadcrumb.lesson}}</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">Questões</a>
        </li>
      </ul>
    </nav>
    <button class="button m-b-md" @click="add">Adicionar</button>

    <b-table
      :data="data"
      :columns="columns"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="user.first_name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button class="m-r-sm" type="is-primary" @click="edit(props)">Editar</b-button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal :active.sync="isCardModalActive" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-on:submit.prevent="addQuest">
              <b-field label="Questão">
                <b-input v-model.trim="quest.title" minlength="3" required></b-input>
              </b-field>

              <b-field label="Descrição">
                <b-input v-model.trim="quest.question" type="textarea"></b-input>
              </b-field>

              <b-field label="Tipo de escolha" required>
                <b-select @input="clear" v-model.trim="quest.type" placeholder="Tipo de escolha">
                  <option value="true_or_false">Verdadeiro ou falso</option>
                  <option value="choice">Escolha Única</option>
                  <option value="multiple_choice">Escolha Múltipla</option>
                </b-select>
              </b-field>

              <b-field v-if="quest.type != 'true_or_false'" label="Número de opções">
                <b-select @input="clear" v-model.trim="numberInput" placeholder="Número de opções">
                  <option v-for="n in 10" :value="n" :key="n">{{ n }}</option>
                </b-select>
              </b-field>

              <div
                v-show="quest.type != 'true_or_false' && numberInput"
                v-for="(n, index) in numberInput"
                :key="n"
              >
                <div class="is-flex">
                  <b-field class="m-r-md" :label="'Opção ' + n">
                    <b-input
                      v-model.trim="quest.choice[index]"
                      v-show="numberInput"
                      type="text"
                      required
                    ></b-input>
                  </b-field>
                  <b-checkbox class="m-t-md" v-model.trim="check[index]">Certa?</b-checkbox>
                </div>
              </div>

              <div class="is-flex" v-if="quest.type === 'true_or_false'">
                <div class="block">
                  <b-radio v-model.trim="check[0]" :native-value="true">Verdadeiro</b-radio>
                  <b-radio v-model.trim="check[0]" :native-value="false">Falso</b-radio>
                </div>
                <!-- <b-field class="m-r-md" label="Opção 1">
                  <b-input type="text" v-model.trim="quest.choice[0]"></b-input>
                </b-field>
                <b-checkbox class="m-t-md" v-model.trim="check[0]">Certa?</b-checkbox>-->
              </div>

              <button class="button is-info m-t-md">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Axios from "axios";

export default {
  data() {
    return {
      breadcrumb: {},
      isCardModalActive: false,
      numberInput: null,
      check: [],
      columns: [
        {
          field: "title",
          label: "Nome",
          sortable: true
        }
        // {
        //   field: "updatedAt",
        //   label: "Última alteração",
        //   sortable: true
        // }
      ],
      quest: {
        title: "",
        question: "",
        type: null,
        choice: [],
        answer: [],
        module: "",
        lesson: ""
      },
      data: [],
      ediInputs: []
    };
  },
  methods: {
    edit(data) {
      this.quest = {
        title: data.row.title,
        question: data.row.question,
        type: data.row.type,
        choice: data.row.choice,
        _id: data.row._id,
        answer: []
      };
      this.numberInput = this.quest.choice.length;
      let temp = [];
      if (data.row.type !== "true_or_false") {
        for (let [index, choice] of this.quest.choice.entries()) {
          for (let answer of data.row.answer) {
            if (answer == choice) {
              temp[index] = true;
            } else {
              if (!temp[index]) {
                temp[index] = false;
              }
            }
            this.check = temp;
          }
        }
      } else {
        this.check[0] = data.row.answer[0];
      }
      this.isCardModalActive = true;
    },
    update() {
      Axios.put(`/v1/questions/${this.quest._id}`, this.quest)
        .then(data => {
          if (data.data) {
            this.getQuest();
            this.isCardModalActive = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/questions/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getQuest();
            this.$buefy.notification.open({
              message: "Curso excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    add() {
      this.quest = {
        title: "",
        question: "",
        type: null,
        choice: [],
        answer: [],
        module: "",
        lesson: ""
      };
      this.clear();
      this.isCardModalActive = true;
    },
    addQuest() {
      let temp = [];
      if (this.quest.type === "true_or_false") {
        this.quest.answer[0] = this.check[0];
      }
      for (let [index, choice] of this.quest.choice.entries()) {
        if (this.check[index]) {
          temp.push(choice);
        }
        this.quest.answer = temp;
      }

      if (!this.quest._id) {
        this.quest.lesson = this.$route.params.name;
        this.quest.module = this.$route.params.mod;
        this.quest.course = this.$route.params.cur;
        Axios.post(`/v1/questions/`, this.quest)
          .then(data => {
            if (data) {
              this.getQuest();
              this.isCardModalActive = false;
              this.$buefy.notification.open({
                message: "Curso criado com sucesso!",
                type: "is-success"
              });
            }
          })
          .catch(erro => {
            console.error(erro);
          });
      } else {
        this.update();
      }
    },
    getQuest() {
      Axios.get(`/v1/questions?lesson=${this.$route.params.name}`)
        .then(data => {
          this.data = data.data.data;
          // data.data.data.map((item, index) => {
          //   this.data[index].updatedAt = new Date(
          //     item.updatedAt
          //   ).toLocaleDateString("pt-BR");
          // });
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> esta questão?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    clear() {
      this.quest.choice = [];
      this.quest.answer = [];
      this.check = [];
    },
    getCourse() {
      Axios.get(`/v1/courses/${this.$route.params.cur}`)
        .then(data => {
          if (data.data) {
            this.breadcrumb.course = data.data.name;
            this.$forceUpdate(this.breadcrumb);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getModulo() {
      Axios.get(`/v1/modules/${this.$route.params.mod}`)
        .then(data => {
          if (data.data) {
            this.breadcrumb.module = data.data.name;
            this.$forceUpdate(this.breadcrumb);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getLesson() {
      Axios.get(`/v1/lessons/${this.$route.params.name}`)
        .then(data => {
          if (data.data) {
            this.breadcrumb.lesson = data.data.name;
            this.$forceUpdate(this.breadcrumb);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  mounted() {
    this.getQuest();
    this.getCourse();
    this.getModulo();
    this.getLesson();
  }
};
</script>

<style>
</style>
